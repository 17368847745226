import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/es';

import Search from "../components/SearchFront/Search";
import SearchResults from "../components/SearchFront/SearchResults";
import LiveRandomLessons from "../components/CarouselsFront/Lessons/Live";
import OfflineRandomLessons from "../components/CarouselsFront/Lessons/Offline";
import ProfessionalsRandom from "../components/CarouselsFront/Professionals/Professionals";

import NavProfile from '../components/UserProfileFront/NavProfile.vue';

const search = createApp({
    components: {
        Search,
    }
}).use(ElementPlus, {locale}).mount('#search');

if(document.getElementById('search_results')) {
    const result = createApp({
        components: {
            SearchResults,
        }
    }).use(ElementPlus, {locale}).mount('#search_results');
}


if(document.getElementById('live_lessons')){
    const live = createApp({
        components: {
            LiveRandomLessons,
        }
    }).use(ElementPlus, {locale}).mount('#live_lessons');
}

if(document.getElementById('offline_lessons')) {
    const offline = createApp({
        components: {
            OfflineRandomLessons,
        }
    }).use(ElementPlus, {locale}).mount('#offline_lessons');
}


if(document.getElementById('offline_lessons')) {
    const professionals = createApp({
        components: {
            ProfessionalsRandom,
        }
    }).use(ElementPlus, {locale}).mount('#professionals_random');
}

if(document.getElementById('navprofile')) {
    const navprofile = createApp({
        components: {
            NavProfile,
        }
    }).use(ElementPlus, {locale}).mount('#navprofile');
}
