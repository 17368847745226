<template>
  <div>
    <nav class="bg-primary container-fluid">
      <div class="container">
        <div class="row pb-1">
          <div class="col-12 col-lg-auto text-center text-lg-start">
            <img :src="professional.profile_photo_url" class="shadow-lg rounded-1 profile-photo">
          </div>
          <div class="col-12 col-lg-10 col-xl-10 pt-2">
            <ul class="nav nav-pills nav-justified nav-profile text-uppercase">
              <li class="nav-item mx-1">
                <label style="cursor: pointer" :class="`nav-link ${show_profile}`" @click="showProfile()">
                  <i class="icon-user-circle small"></i>
                  <span class="d-none d-md-block d-lg-inline-block ms-xl-1">Perfil</span>
                </label>
              </li>
              <li class="nav-item mx-1">
                <label style="cursor: pointer" :class="`nav-link ${show_lives}`" @click="showLives()">
                  <i class="icon-live small"></i>
                  <span class="d-none d-md-block d-lg-inline-block ms-xl-1">Directos</span>
                </label>
              </li>
              <li class="nav-item mx-1">
                <label style="cursor: pointer" :class="`nav-link ${show_offlines}`" @click="showOfflines()">
                  <i class="icon-play-circle-regular small"></i>
                  <span class="d-none d-md-block d-lg-inline-block ms-xl-1">Vídeos</span>
                </label>
              </li>
              <template v-if="subscribed">
                <li class="nav-item mx-1">
                  <a class="nav-link btn btn-warning bg-warning text-white shadow-lg" href="/billing-redirect">
                    <i class="icon-heart small"></i> 
                    <span class="d-none d-md-block d-lg-inline-block ms-xl-1">Suscribirse</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <template v-if="show_profile">
      <profile :professional="professional" />
    </template>
    <template v-if="show_lives">
      <lesson-results :filterlives="true" :user_id="professional.id" />
    </template>
    <template v-if="show_offlines">
      <lesson-results :filtervideos="true" :user_id="professional.id" />
    </template>
  </div>
</template>
<script>
  import Profile from './Profile.vue';
  import LessonResults from './../SearchFront/LessonResults.vue';
  export default {
    name: "NavProfile",
    props: ['professional', 'subscribed'],
    components: {
      Profile,
      LessonResults,
    },
    data() {
      return {
        show_profile: 'active',
        show_lives: '',
        show_offlines: '',
      }
    },
    methods: {
      showProfile() {
        this.show_profile = 'active';
        this.show_lives = '';
        this.show_offlines = '';
      },
      showLives() {
        this.show_profile = '';
        this.show_lives = 'active';
        this.show_offlines = '';
      },
      showOfflines() {
        this.show_profile = '';
        this.show_lives = '';
        this.show_offlines = 'active';
      }
    },
  }
</script>