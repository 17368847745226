<template>
  <div>
    <div class="container-fluid bg-light py-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <input v-model="showall" type="checkbox" class="btn-check" id="btn-check-outlined-2" checked autocomplete="off">
            <label :class="`btn btn-sm btn-outline-primary rounded-pill me-2 ${showall ? 'text-white' : ''}`" for="btn-check-outlined-2">Todos</label>

            <input v-model="showvideos" type="checkbox" class="btn-check" id="btn-check-outlined-3" autocomplete="off">
            <label :class="`btn btn-sm btn-outline-primary rounded-pill me-2 ${showvideos ? 'text-white' : ''}`" for="btn-check-outlined-3">Videos</label>

            <input v-model="showlives" type="checkbox" class="btn-check" id="btn-check-outlined-4" autocomplete="off">
            <label :class="`btn btn-sm btn-outline-primary rounded-pill me-2 ${showlives ? 'text-white' : ''}`" for="btn-check-outlined-4">Lives</label>

            <input v-model="showprofiles" type="checkbox" class="btn-check" id="btn-check-outlined-5" autocomplete="off">
            <label :class="`btn btn-sm btn-outline-primary rounded-pill ${showprofiles ? 'text-white' : ''}`" for="btn-check-outlined-5">Perfiles</label>
          </div>
        </div>
      </div>
    </div>
    <professional-carousel
        v-if="!showvideos && !showlives"
        :category_id="category_id"
        :query="query"
    />
    <lesson-results
        v-if="!showprofiles"
        :category_id="category_id"
        :query="query"
        :filtervideos="showvideos"
        :filterlives="showlives"
    />
  </div>
</template>

<script>
import ProfessionalCarousel from "./ProfessionalCarousel";
import LessonResults from "./LessonResults";
export default {
  name: "SearchResults",
  components: {LessonResults, ProfessionalCarousel},
  data() {
    return {
      showall: false,
      showvideos: false,
      showlives: false,
      showprofiles: false,
    }
  },
  props: {
    category_id: {
      type: Number,
      default: null
    },
    query: {
      type: String,
      default: ''
    },
  },
  watch: {
    showall(newValue){
      if(newValue){
        this.showvideos = false;
        this.showlives = false;
        this.showprofiles = false;
      }
    },
    showvideos(newValue){
      if(newValue){
        this.showall = false;
        this.showlives = false;
        this.showprofiles = false;
      }
    },
    showlives(newValue){
      if(newValue){
        this.showall = false;
        this.showvideos = false;
        this.showprofiles = false;
      }
    },
    showprofiles(newValue){
      if(newValue){
        this.showall = false;
        this.showvideos = false;
        this.showlives = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
