<template>
  <div v-if="!!professionals.length" class="container-fluid container-slider px-2 px-md-2 px-lg-5">
    <vueper-slides
      class="no-shadow"
      :visible-slides="4"
      slide-multiple
      :gap="3"
      :bullets="false"
      fixed-height="525px"
      :dragging-distance="75"
      :parallax="false"
      :parallax-fixed-content="false"
      :breakpoints="{
        600: { autoplay: true, arrows: false, visibleSlides: 2, slideMultiple: 2 },
        768: { autoplay: true, arrows: false, visibleSlides: 2, slideMultiple: 2 },
        992: { autoplay: true, arrows: false, visibleSlides: 3, slideMultiple: 3 },
        1200: { visibleSlides: 4, slideMultiple: 4 } }"
    >
      <vueper-slide
        v-for="(professional, key) in professionals"
        :key="key"
      >
        <template v-slot:content>
          <div class="vueperslide__content-wrapper">
            <div class="card card-trainner shadow w-100">
                <a :href="`/${ professional.slug }`">
                    <img height="292" :src="professional.profile_photo_url" class="card-img-top">
                    <div class="card-body">
                        <p class="fw-bold h5 text-dark mb-0 text-truncate">{{ professional.profile_name }}</p>
                        <p class="text-primary fw-light small mb-0">{{ professional.data_front_lessons }}</p>
                    </div>
                    <div class="card-footer text-white text-center p-3">
                        VER PERFIL
                    </div>
                </a>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "ProfessionalCarousel",
  components: {
    VueperSlides,
    VueperSlide
  },
  data(){
    return {
      professionals: [],
    }
  },
  mounted() {
    this.getProfessionals();
  },
  methods: {
    getProfessionals() {
      axios.get('/api/random-professionals', {
        params: {
          name: this.query,
          category_id: this.category_id,
        }
      }).then(result => {
        this.professionals = result.data;
      }).catch(error => {
        console.log(error);
      })
    }
  },
}
</script>
