<template>
  <div v-if="!!lessonsLive.length" class="container-fluid container-slider px-2 px-md-2 px-lg-5 pb-5">
    <div class="row">
      <div class="col-12 pt-5">
        <h5 class="fw-light mb-0 h6 subtitle">Próximos</h5>
        <h4 class="h4 title mt-0">Directos</h4>
      </div>
    </div>
    <vueper-slides
      class="no-shadow"
      :visible-slides="3"
      slide-multiple
      :gap="3"
      :bullets="false"
      :slide-ratio="1 / 5"
      :dragging-distance="75"
      :parallax="false"
      :parallax-fixed-content="false"
      :breakpoints="{ 
        600: { autoplay:true, arrows: false, visibleSlides: 1, slideMultiple: 1, slideRatio: 1 / 2 },
        768: { autoplay:true, arrows: false, visibleSlides: 2, slideMultiple: 2, slideRatio: 1 / 3 }, 
        992: { autoplay:true, arrows: false, visibleSlides: 2, slideMultiple: 2, slideRatio: 1 / 4 }, 
        1200: { visibleSlides: 3, slideMultiple: 3, slideRatio: 1 / 4 } }"
    >
      <vueper-slide v-for="(lesson, key) in lessonsLive" :key="key">
        <template v-slot:content>
          <div class="vueperslide__content-wrapper">
            <div class="card card-live shadow rounded-1 border-0 w-100">
              <div class="row g-0">
                <div class="col-4 col-lg-5" :style="'background:url('+lesson.imageUrl+') no-repeat center; background-size:cover;min-height:175px;'">
                </div>
                <div class="col-8 col-lg-7 my-auto">
                  <div class="card-body">
                    <p class="badge bg-danger rounded-pill px-2 fs-xs">
                      LIVE
                      <i class="icon-live small ps-1"></i>
                    </p>
                    <p class="fs-xs text-muted">
                      {{ lesson.formated_date}} // {{lesson.formated_time_start}}
                    </p>
                    <h5 class="card-title text-dark h6 text-truncate">{{ lesson.name }}</h5>
                    <p>
                      <a class="stretched-link small" :href="'/'+lesson.user.slug+'/'+lesson.slug">Ver info</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  name: "LiveLessonCarousel",
  components: {
    VueperSlides,
    VueperSlide
  },
  props: ['user_id', 'lesson_id'],
  data(){
    return {
      lessonsLive: [],
    }
  },
  mounted() {
    this.fetchRandomLessons();
  },
  methods: {
    fetchRandomLessons() {
      axios.get('/api/random-lessons-live', {
        params: {
          'user_id': this.user_id,
          'lesson_id': this.lesson_id,
        }
      }).then(result => {
        this.lessonsLive = result.data;
      }).catch(error => {
        console.log(error);
      })
    }
  },
}
</script>
