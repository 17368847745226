<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 pt-5 my-lg-5 pb-4">
        <h2 class="h3 title">Sobre mi</h2>
        <h3 class="h4">{{professional.userable.slogan}}</h3>
        <p class="text-justify">{{professional.userable.about_me.substring(0, maxLength)}}{{ points }}</p>
        <template v-if="professional.userable.about_me.length > 900">
          <button class="btn btn-primary px-3 px-md-5 border-0 rounded-1 w-100" type="button" @click="showMore()">
            <span class="d-none d-lg-inline">{{ maxLength > 900 ? 'Ver menos' : 'Ver mas'}}</span>
          </button>
        </template>
      </div>
      <template v-if="!!images.length">
        <div class="col-lg-6 pt-lg-5">
          <vueper-slides
            class="shadow-lg rounded-1"
            ref="vueperslides1"
            fade
            :autoplay="false"
            :bullets="false"
            @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
            fixed-height="400px"
            :breakpoints="{ 
              600: { arrows: false},
              768: { arrows: false}, 
              992: { arrows: false} }"
          >
            <vueper-slide
              v-for="(slide, i) in images"
              :key="i"
              :image="slide.url"
            />
          </vueper-slides>

          <vueper-slides
            class="no-shadow thumbnails mt-3"
            ref="vueperslides2"
            @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
            :visible-slides="images.length"
            fixed-height="75px"
            :bullets="false"
            :touchable="false"
            :gap="2.5"
            :arrows="false"
          >
          <vueper-slide
            v-for="(slide, i) in images"
            :key="i"
            :image="slide.url"
            @click.native="$refs.vueperslides2.goToSlide(i)"
          />
          </vueper-slides>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import {VueperSlides, VueperSlide} from 'vueperslides';
  import 'vueperslides/dist/vueperslides.css';
  export default {
    components: {
      VueperSlides,
      VueperSlide
    },
    name: 'Profile',
    props: ['professional'],
    data() {
      return {
        images:[],
        maxLength: 900,
        points: '',
      }
    },
    mounted() {
      this.getUserGaleryImages();
      this.points = this.professional.userable.about_me.length > 900 ? '...' : '';
    },
    methods: {
      getUserGaleryImages() {
        axios.get(`/api/users/${this.professional.id}/galery`)
        .then(res => {
          this.images = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
      },
      showMore() {
        if (this.maxLength > 900) {
          this.maxLength = 900;
          this.points = '...';
        }else{
          this.maxLength = this.professional.userable.about_me.length;
          this.points = '';
        }
      }
    }
  }
</script>
<style>
.vueperslide{
  border-radius:0.2rem !important;
}
.thumbnails {
  margin: auto;
  max-width: 100%;
}
.thumbnails .vueperslide {
  box-sizing: border-box;
  border-radius:0.2rem !important;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
  width:55px;
}

.thumbnails .vueperslide--active {
  border: 1px solid transparent;
  opacity: 1;
}
</style>