<template>
  <div class="container-fluid bg-light py-4">
    <div class="container">
      <div
        class="row"
        v-infinite-scroll="loadLessons"
        infinite-scroll-disabled="disabled"
      >
        <div class="col-12 col-sm-6 my-3 infinite-list-item" v-for="(lesson, key) in lessons" :key="key">
          <div class="card card-live shadow-lg rounded-1 border-0">
            <div class="row g-0">
              <div class="col-lg-5" :style="`background:url(${lesson.imageUrl}) no-repeat center; background-size:cover;min-height:150px;`">
              </div>
              <div class="col-lg-7">
                <div class="card-body">
                  <p v-if="lesson.typeClass === 2" class="badge bg-danger rounded-pill px-2">
                    LIVE
                    <i class="icon-live small ps-1"></i>
                  </p>
                  <p v-else-if="lesson.typeClass === 1" class="badge bg-info rounded-pill px-2">
                    VIDEO
                    <i class="icon-play-circle-regular small ps-1"></i>
                  </p>
                  <p class="fs-xs my-2">
                    <img :src="lesson.user.profile_photo_url" class="rounded-pill d-inline mb-1" width="24">
                    {{ lesson.user.profile_name }}
                  </p>
                  <h6 class="card-title text-dark m-0 text-truncate">{{ lesson.name }}</h6>
                  <p class="fs-xs text-muted my-0">
                    <template v-if="lesson.typeClass === 2">
                      {{ lesson.formated_date}} // {{lesson.formated_time_start}}
                    </template>
                    <template v-else>
                      Disponible
                    </template>
                  </p>
                  <p class="small text-truncate my-0">
                    {{ lesson.description }}
                  </p>
                  <p class="small">
                    <a v-if="lesson.typeClass === 2" class="stretched-link" :href="lesson.show_url">Ver info</a>
                    <a v-else-if="lesson.typeClass === 1" class="stretched-link" :href="lesson.show_url">Ver vídeo</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-4">
          <h6 class="" v-if="loading"><i class="el-icon-loading"></i> Cargando...</h6>
          <p v-if="noMore">No hay nada más que mostrar.</p>
          <p v-if="noMoreNothing">No hay clases que concuerden con su busqueda.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LessonResults",
  props: {
    category_id: {
      type: Number,
      default: null
    },
    query: {
      type: String,
      default: ''
    },
    filtervideos: {
      type: Boolean,
      default: false
    },
    filterlives: {
      type: Boolean,
      default: false
    },
    user_id: {
      type: Number,
      default: null
    },
  },
  created() {
    this.loadLessons();
  },
  data(){
    return {
      lessons: [],
      page: 1,
      limit_pages: false,
      loading: false,
    }
  },
  methods: {
    loadLessons () {
      this.loading = true;
      axios.get('/api/lessons', {
        params: {
          active: true,
          page: this.page,
          name: this.query,
          filtervideos: this.filtervideos ? this.filtervideos : null,
          filterlives: this.filterlives ? this.filterlives : null,
          category_id: this.category_id,
          user_id: this.user_id,
        }
      }).then(result => {
        this.lessons = this.lessons.concat(result.data.data);
        this.limit_pages = result.data.meta.last_page;
        this.loading = false;
        this.page++;
      }).catch(error => {
        console.log('loadLessons');
        console.log(error);
      })
    }
  },
  computed: {
    noMore () {
      return this.limit_pages && this.limit_pages < this.page && !!this.lessons.length;
    },
    noMoreNothing () {
      return this.limit_pages && this.limit_pages < this.page && !this.lessons.length;
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
  watch: {
    filtervideos(newValue){
      this.lessons = [];
      this.page = 1;
      this.limit_pages = false
      this.$nextTick(() => {
        this.loadLessons();
      })
    },
    filterlives(newValue){
      this.lessons = [];
      this.page = 1;
      this.limit_pages = false
      this.$nextTick(() => {
        this.loadLessons();
      })
    },
  }
}
</script>

<style scoped>

</style>
