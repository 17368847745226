<template>
  <div v-if="!!professionals.length" class="container-fluid bg-white shadow-lg p-0">
    <div class="container-fluid container-slider px-0 px-md-2 px-lg-5">
      <vueper-slides
        class="no-shadow"
        :visible-slides="4"
        slide-multiple
        :gap="3"
        :bullets="false"
        fixed-height="250px"
        :dragging-distance="75"
        :parallax="false"
        :parallax-fixed-content="false"
        :breakpoints="{ 
          600: { autoplay:true, arrows: false, visibleSlides: 1, slideMultiple: 1 },
          768: { autoplay:true, arrows: false, visibleSlides: 2, slideMultiple: 2 }, 
          992: { autoplay:true, arrows: false, visibleSlides: 2, slideMultiple: 2 }, 
          1200: { visibleSlides: 3, slideMultiple: 3 } }"
      >
        <vueper-slide
          v-for="(professional, key) in professionals"
          :key="key"
        >
          <template v-slot:content>
            <div class="vueperslide__content-wrapper">
              <div class="card card-live shadow-sm rounded-1">
                <div class="row justify-content-center g-0">
                  <div class="col-3">
                    <div class="card-body text-center py-sm-3 pb-0 ps-3 pe-2">
                      <img :src="professional.profile_photo_url" class="rounded-pill img-fluid">
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="card-body py-3 ps-2 pe-3">
                      <h6 class="card-title text-dark m-0 text-truncate">
                        {{ professional.profile_name }}
                      </h6>
                      <p class="fs-xs text-muted my-0">
                        {{ professional.data_front_lessons }}
                      </p>
                      <p class="small text-truncate my-0">
                        {{ professional.about_me }}
                      </p>
                      <p class="small">
                        <a class="stretched-link" :href="professional.slug">Ver perfil</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "ProfessionalCarousel",
  components: {
    VueperSlides,
    VueperSlide
  },
  props: {
    category_id: {
      type: Number,
      default: null
    },
    query: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      professionals: [],
    }
  },
  mounted() {
    this.getProfiles();
  },
  methods: {
    getProfiles() {
      axios.get('/api/professionals', {
        params: {
          name: this.query,
          category_id: this.category_id,
        }
      }).then(result => {
        this.professionals = result.data.data;
      }).catch(error => {
        console.log('loadLessons');
        console.log(error);
      })
    }
  },
}
</script>

<style>

</style>
